import React from 'react';

function Project({ name, description, technologies, githubLink, projectLink = null }) {
  return (
    <div className="project">
      <h2 className="project-name">{name}</h2>
      <p className="project-description">{description}</p>
      <ul className="project-technologies">
        {technologies.map((tech, index) => (
          <li key={index} className="technology-item">
            {tech}
          </li>
        ))}
      </ul>

      {/* Conditional rendering for GitHub link */}
      {githubLink && (
        <a
          href={githubLink}
          target="_blank"
          rel="noopener noreferrer"
          className="button-github"
        >
          View on GitHub
        </a>
      )}

      {/* Conditional rendering for Live Project link */}
      {projectLink && (
        <a
          href={projectLink}
          target="_blank"
          rel="noopener noreferrer"
          className="button-project"
        >
          View Live Project
        </a>
      )}
    </div>
  );
}

export default Project;