import React, { useState } from "react";
import "./Navbar.css"; // Asigură-te că ai un fișier CSS pentru stilizare

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="navbar">
      <div className="navbar-toggle" onClick={toggleMenu}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <line x1="3" y1="12" x2="21" y2="12"></line>
          <line x1="3" y1="6" x2="21" y2="6"></line>
          <line x1="3" y1="18" x2="21" y2="18"></line>
        </svg>
      </div>
      <div className={`navbar-links ${isOpen ? "active" : ""}`}>
        <a href="http://cantina.pavalsebastian.com/" target="_blank" rel="noreferrer">
          Cantina - Web
        </a>
        <a href="http://chat.pavalsebastian.com/" target="_blank" rel="noreferrer">
          Chat - Web
        </a>
        <a href="http://letmecook.pavalsebastian.com/" target="_blank" rel="noreferrer">
          LetMeCook - Web
        </a>
        <a href="https://wedding.pavalsebastian.com/" target="_blank" rel="noreferrer">
          Wedding - Web
        </a>
      </div>
    </div>
  );
}

export default Navbar;